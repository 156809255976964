import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  OnDestroy,
  ChangeDetectorRef,
  Inject,
  LOCALE_ID,
} from '@angular/core';
import { AppStoreFacade } from 'app/app-store/app-store-facade/app-store.facade';
import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Activation, ActivationStatus } from 'app/models';
import * as dayjs from 'dayjs';
import * as LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { DevicesFacade } from 'app/app-store/devices-store/devices.facade';
import { ActivationsFacade } from 'store/activations-store/activations.facade';
import { SubscriptionCategoryPipe } from 'app/shared/pipe/subscription-category.pipe';

dayjs.extend(LocalizedFormat);
require('dayjs/locale/de');
require('dayjs/locale/fr');

@Component({
  selector: 'pro-global-notice',
  templateUrl: './global-notice.component.html',
  styleUrls: ['./global-notice.component.scss'],
  animations: [
    trigger('inOutModalAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0.3s ease', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('0.3s ease', style({ opacity: 0 }))]),
    ]),
  ],
  providers: [SubscriptionCategoryPipe],
})
export class GlobalNoticeComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  isOffline = true;
  activationExpired = false;
  showOfflineInfoModal = true;
  bluetoothDisabled = false;
  locationDisabled = false;
  activationNoticeHeadline = '';
  permissionStatus = '';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private appStoreFacade: AppStoreFacade,
    private activationsFacade: ActivationsFacade,
    private devicesFacade: DevicesFacade,
    private cdRef: ChangeDetectorRef,
    private subscriptionCategoryPipe: SubscriptionCategoryPipe,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  ngOnInit(): void {
    // In order to preload assets for global notice, add and remove offline-notice and-modal from DOM
    this.cdRef.detectChanges();
    this.isOffline = false;
    this.showOfflineInfoModal = false;

    combineLatest([
      this.appStoreFacade.isOffline$,
      this.activationsFacade.activationsCurrentState$,
      this.devicesFacade.isBluetoothEnabled$,
      this.devicesFacade.isLocationEnabledAndAvailable$,
      this.devicesFacade.hasDevicesConnected$,
    ])
      .pipe(
        map(
          ([
            isOffline,
            activationsCurrentState,
            isBluetoothEnabled,
            isLocationEnabledAndAvailable,
            hasDevicesConnected,
          ]) => ({
            isOffline,
            activationsCurrentState,
            isBluetoothEnabled,
            isLocationEnabledAndAvailable,
            hasDevicesConnected,
          })
        ),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((d) => {
        // on-/offline
        this.isOffline = d.isOffline;

        // app-subscription status
        this.checkAppSubscriptions(
          d.activationsCurrentState.currentStatus,
          Object.values(d.activationsCurrentState.entities)
        );

        // bluetooth and location infos are only displayed if user has a device connected
        this.bluetoothDisabled = d.hasDevicesConnected ? !d.isBluetoothEnabled : false;
        this.locationDisabled = d.hasDevicesConnected ? !d.isLocationEnabledAndAvailable : false;

        // show bluetooth- and location warnings only if the user is logged in
        // use app-subscription-status to determine if the user is logged in
        if (d.activationsCurrentState?.currentStatus === null) {
          this.bluetoothDisabled = false;
          this.locationDisabled = false;
        }

        this.setDisplayNoticeStatus();
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  checkAppSubscriptions(currentStatus: ActivationStatus, appSubscriptions: Activation[]) {
    this.activationExpired = false;

    if ([null, 'active', 'enqueued'].includes(currentStatus)) {
      if (currentStatus === 'active') {
        const activeSubscription = appSubscriptions.find(
          (subscription) => subscription.status === 'active'
        );
        const { category } = activeSubscription;

        if (activeSubscription) {
          // ignores certain statuses
          if (
            ['telemedicine_center', 'telemedicine_center_test', 'tmc_eheart'].includes(category)
          ) {
            return;
          }

          // checks for enqueued app-subscriptions
          const enqueuedSubscription = appSubscriptions.find(
            (subscription) => subscription.status === 'enqueued'
          );

          if (enqueuedSubscription) {
            return;
          }

          // shows a notice for the last three days in a active test-subscription and for the last 7 days for other categories
          const differenceToCheck = category === 'test' ? 3 : 7;
          const daysLeft = dayjs(activeSubscription.end_date, 'YYYY-MM-DD').diff(dayjs(), 'd');

          if (daysLeft < differenceToCheck) {
            this.activationExpired = true;
            this.activationNoticeHeadline = $localize`${this.subscriptionCategoryPipe.transform(
              category
            )} läuft ab`;
          }
        }
      }
    } else {
      this.activationNoticeHeadline = $localize`Dein Abo ist abgelaufen!`;
      this.activationExpired = true;
    }
  }

  setDisplayNoticeStatus() {
    let showNotice = false;
    if (
      this.activationExpired === true ||
      this.isOffline === true ||
      this.bluetoothDisabled === true ||
      this.locationDisabled === true
    ) {
      showNotice = true;
    } else {
      showNotice = false;
    }

    this.cdRef.detectChanges();
    let RouterOutletElement: Node;
    const siblings = this.el.nativeElement.parentNode.childNodes as NodeList;
    const newTopPos: number = this.el.nativeElement.offsetHeight
      ? this.el.nativeElement.offsetHeight
      : 96;

    siblings.forEach((c) => {
      if (c.nodeName.includes('ROUTER-OUTLET')) {
        RouterOutletElement = c;
      }
    });

    if (RouterOutletElement) {
      if (showNotice === true) {
        this.renderer.setStyle(RouterOutletElement, 'top', `${newTopPos}px`);
      } else {
        this.renderer.setStyle(RouterOutletElement, 'top', '0px');
      }
    }

    if (showNotice === true) {
      this.renderer.addClass(this.el.nativeElement, 'is-visible');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'is-visible');
    }
    this.cdRef.detectChanges();
  }

  requestPermission() {
    this.permissionStatus = '';
    this.devicesFacade
      .requestLocationPermission()
      .then((status) => (this.permissionStatus = status));
  }
}
